/**
 * NewsItemView view component.
 * @module components/theme/View/NewsItemView
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { FormattedDate } from '@plone/volto/components';
import config from '@plone/volto/registry';
import { Container as SemanticContainer } from 'semantic-ui-react';
import { getUser } from '@plone/volto/actions';
import { useDispatch, useSelector } from 'react-redux';

/**
 * NewsItemView view component class.
 * @function NewsItemView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const NewsItemView = ({ content }) => {
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;
  const user = useSelector((state) => state.users.user);
  const userId = content?.creators ? content.creators[0] : '';

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUser(userId));
  }, [dispatch, userId]);

  return (
    <Container id="page-document" className="view-wrapper newsitem-view">
      <div className="dates">
        {content?.effective ? (
          <span className="day">
            <FormattedDate date={content?.effective} />{' '}
          </span>
        ) : (
          <span className="day">No date</span>
        )}{' '}
        {content?.head_title && (
          <span className="head-title"> {content?.head_title}</span>
        )}
        {userId && (
          <span className="head-title"> {user?.fullname || userId}</span>
        )}
      </div>
      <RenderBlocks content={content} />
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
NewsItemView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default NewsItemView;
