import { Grid } from 'semantic-ui-react';
import cx from 'classnames';
import { RenderBlocks } from '@plone/volto/components';
import { withBlockExtensions } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import { flattenToAppURL } from '@plone/volto/helpers';
import { UniversalLink } from '@plone/volto/components';
import { Button } from 'semantic-ui-react';

const GridBlockView = (props) => {
  const { data, path, className, style } = props;
  const metadata = props.metadata || props.properties;
  const columns = data.blocks_layout.items;
  const blocksConfig =
    config.blocks.blocksConfig[data['@type']].blocksConfig ||
    props.blocksConfig;
  const location = {
    pathname: path,
  };

  let link = null;
  let href = data.linkHref?.[0]?.['@id'] || '';
  if (isInternalURL(href)) {
    link = (
      <UniversalLink href={flattenToAppURL(href)}>
        <Button className="align left">{data.linkTitle || href}</Button>
      </UniversalLink>
    );
  } else if (href) {
    link = (
      <UniversalLink href={href}>
        <Button className="align left">{data.linkTitle || href}</Button>
      </UniversalLink>
    );
  }

  return (
    <div
      className={cx('block', data['@type'], className, {
        one: columns?.length === 1,
        two: columns?.length === 2,
        three: columns?.length === 3,
        four: columns?.length === 4,
      })}
      style={style}
    >
      {data.headline && <h2 className="headline">{data.headline}</h2>}

      <Grid stackable stretched columns={columns.length}>
        <RenderBlocks
          {...props}
          blockWrapperTag={Grid.Column}
          metadata={metadata}
          content={data}
          location={location}
          blocksConfig={blocksConfig}
          isContainer
        />
        {link && <div className={'gridLinkWrapper'}>{link}</div>}
      </Grid>
    </div>
  );
};

export default withBlockExtensions(GridBlockView);
