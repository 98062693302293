import { defineMessages } from 'react-intl';
import { composeSchema } from '@plone/volto/helpers';
import { defaultStylingSchema } from '@kitconcept/volto-light-theme/components/Blocks/schema';

const BG_COLORS = [
  { name: 'transparent', label: 'Transparent' },
  { name: 'white_black', label: 'White / Black' },
  { name: 'red_white', label: 'Red / White' },
  { name: 'grey_white', label: 'Grey / White' },
  { name: 'orange_white', label: 'Orange / White' },
  { name: 'blue_white', label: 'Blue / White' },
  { name: 'green_white', label: 'Green / White' },
];

const messages = defineMessages({
  more: {
    id: 'Link more',
    defaultMessage: 'Link more',
  },
  LinkTitle: {
    id: 'Link title',
    defaultMessage: 'Link Title',
  },
  LinkTo: {
    id: 'Link to',
    defaultMessage: 'Link to',
  },
});

const GridSchemaEnhancer = ({ schema, formData, intl }) => {
  schema.fieldsets = [
    ...schema.fieldsets,
    {
      id: 'linkmore',
      title: intl.formatMessage(messages.more),
      fields: ['linkTitle', 'linkHref'],
    },
  ];
  schema.properties = {
    ...schema.properties,
    linkTitle: {
      title: intl.formatMessage(messages.LinkTitle),
    },
    linkHref: {
      title: intl.formatMessage(messages.LinkTo),
      widget: 'object_browser',
      mode: 'link',
      selectedItemAttrs: ['Title', 'Description'],
      allowExternals: true,
    },
  };
  return schema;
};

const applyConfig = (config) => {
  config.settings = {
    ...config.settings,
    isMultilingual: false,
    supportedLanguages: ['de'],
    defaultLanguage: 'de',
    navDepth: 3,
    openExternalLinkInNewTab: true,
  };

  // Listing
  config.blocks.blocksConfig.listing = {
    ...config.blocks.blocksConfig.listing,
    showLinkMore: true,
    colors: BG_COLORS,
  };

  // Teaser
  config.blocks.blocksConfig.teaser = {
    ...config.blocks.blocksConfig.teaser,
    colors: BG_COLORS,
  };

  // Grid
  config.blocks.blocksConfig.gridBlock = {
    ...config.blocks.blocksConfig.gridBlock,
    schemaEnhancer: composeSchema(defaultStylingSchema, GridSchemaEnhancer),
    colors: BG_COLORS,
  };

  // Navigation
  config.settings.enableFatMenu = false;

  // Code Block
  config.blocks.blocksConfig.codeBlock = {
    ...config.blocks.blocksConfig.codeBlock,
    mostUsed: true,
  };

  // Slate
  config.blocks.blocksConfig.slate = {
    ...config.blocks.blocksConfig.slate,
    colors: BG_COLORS,
  };

  // Fix for code button of slate
  config.settings.slate.buttons = {
    ...config.settings.slate.buttons,
  };

  config.settings.slate.toolbarButtons = [
    'bold',
    'italic',
    'strikethrough',
    'code',
    'separator',
    'link',
    'separator',
    'heading-two',
    'heading-three',
    'separator',
    'numbered-list',
    'bulleted-list',
    'blockquote',
    'styleMenu',
  ];

  return config;
};

export default applyConfig;
